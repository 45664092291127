import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Quick_Installation/WebUI_And_Firmware_Upgrade/VGA_Cameras/PrimaryBox';
import DangerBox from 'components/Quick_Installation/WebUI_And_Firmware_Upgrade/VGA_Cameras/DangerBox';
import NavButtons from 'components/Quick_Installation/WebUI_And_Firmware_Upgrade/NavButtons';
import ForumBox from 'components/Quick_Installation/WebUI_And_Firmware_Upgrade/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Software Update VGA Series",
  "path": "/Quick_Installation/WebUI_And_Firmware_Upgrade/VGA_Cameras/",
  "dateChanged": "2017-11-24",
  "author": "Mike Polinowski",
  "excerpt": "WebUI and Firmware Upgrade",
  "image": "./QI_SearchThumb_WebUIFirmwareUpgrade_VGA.png",
  "social": "/images/Search/QI_SearchThumb_WebUIFirmwareUpgrade_VGA.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-WebUI_And_Firmware_Upgrade_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Software Update VGA Series' dateChanged='2017-11-24' author='Mike Polinowski' tag='INSTAR IP Camera' description='WebUI and Firmware Upgrade' image='/images/Search/QI_SearchThumb_WebUIFirmwareUpgrade_VGA.png' twitter='/images/Search/QI_SearchThumb_WebUIFirmwareUpgrade_VGA.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/WebUI_And_Firmware_Upgrade/VGA_Kameras/' locationFR='/fr/Quick_Installation/WebUI_And_Firmware_Upgrade/VGA_Cameras/' crumbLabel="Software Update" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "webui-and-firmware-upgrade",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#webui-and-firmware-upgrade",
        "aria-label": "webui and firmware upgrade permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`WebUI and Firmware Upgrade`}</h2>
    <h3 {...{
      "id": "vga-camera-series",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#vga-camera-series",
        "aria-label": "vga camera series permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`VGA Camera Series`}</h3>
    <h4 {...{
      "id": "install-the-newest-firmware-and-web-user-interface-on-your-ip-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#install-the-newest-firmware-and-web-user-interface-on-your-ip-camera",
        "aria-label": "install the newest firmware and web user interface on your ip camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Install the newest Firmware and Web User Interface on your IP Camera`}</h4>
    <p>{`Please download the newest version of the WebUI or Firmware from our website. Go to the `}<a href="http://download.instar.de/" target="_blank" rel="noopener noreferrer">{`Download`}</a>{` section on our website. For older Version, please check out our `}<a parentName="p" {...{
        "href": "/en/Downloads/"
      }}>{`Legacy Software`}</a>{` (`}<strong parentName="p">{`only for advanced users`}</strong>{`). Please select your camera model and scroll to the end of the page for the corresponding download links. `}<strong parentName="p">{`Please read the instructions carefully`}</strong>{` to make an informed decision on which Software you have to download. Both updates - web user interface and firmware - can either be done through the INSTAR camera tool or the camera´s webUI and the update procedure is the same for `}<a parentName="p" {...{
        "href": "https://wiki.instar.com/dl/Camera_Tool/INSTAR_Camera_Tool_2.0.7.msi.zip"
      }}>{`Windows`}</a>{`, `}<a href="https://itunes.apple.com/de/app/instar-camera-tool/id584435076?mt=12#" target="_blank" rel="noopener noreferrer">{`macOS`}</a>{` and `}<a parentName="p" {...{
        "href": "https://wiki.instar.com/dl/Camera_Tool/INSTAR_Camera_Tool_LINUX.7z"
      }}>{`LINUX`}</a>{` respectively.`}</p>
    <h4 {...{
      "id": "download",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#download",
        "aria-label": "download permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Download`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e29a99e2bec7a3162b6af1d230a676d9/00d43/Download-WebUI-Firmware-en.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACn0lEQVQ4y3WSa08TURCG999wk1IoLdSlyK2NSClBLkUiXkL8GxqVHwTSchchBAPEGL4YJRD9QIm0dLtLL3u2e5nXzKEgxrDJm9nsOfPMzDurJKeeUnswDH9bCM3+drT4gyyqxf/kbw3dKNAeRjCkoi3QibZABzqDIShv370jNRKh4aE4jcSHqanJT3UNPjQ0tlB9gw+suvpmGRub/BLqa7kq3OwLwNcSQOO9VnRF+jCReARldGwKHe1hGg+qNBbupsT9HlJ7YlDVXop0D6Ar0o+B6BD4/UFPVEZV7YXa1Yf+gSHEHiYQjcXR2zeIULgXytr6Jm1v7eDb7h6+ftzC7MwrJKdnKfnkJY1PPqN4IomR0WkaHplCYmQKw7f0eHwGY5PPMZF8gcH4JF6/mYNimgJCWGQ5DgnbprWNT1heWafdzwdYXdtEemmV0ktrWFpeR2p1AymO6VWpxdQKFlPLMs4vpHFw8AVKtVplICxhwXNdACDP8/BhYZ4KBY0sy0KpVKJSpQJs7wA/f+Gup1gsQrFqQNfz4LouHMehg/19xKIxOjw8pPPf5zg9zdDFRR7GaQbGeRbG5SUMw5AqlUowTRNcOJ/P/+3QIwJ3Zts26XqBD6lSqRBf0rQCcaKwLCnOuS2GOY4DTdMYaEsg1YB84DEdkBeFECRMExXTRMmxIRwHjm1z4X/EuYVCAQon2bYjPfCuxib24uT4mLuiXC6HbC5HuqZBbO9AHJ/A8lxZjLtjGEe2SwKlf1fLuPYQmcwZ5t7P4ejoSBrNYxu6geL3HyhmzsALYgsYei3O03UdChvLpl53yBbk8xr29/YkkGHZbBblchnCdWE59p0eSmAum5Mf5P9CJEcQppBwIcTVNnWjFnVUyuUbSLU29jWQ7/wBAxc5gj0qA1cAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e29a99e2bec7a3162b6af1d230a676d9/e4706/Download-WebUI-Firmware-en.avif 230w", "/en/static/e29a99e2bec7a3162b6af1d230a676d9/d1af7/Download-WebUI-Firmware-en.avif 460w", "/en/static/e29a99e2bec7a3162b6af1d230a676d9/7f308/Download-WebUI-Firmware-en.avif 920w", "/en/static/e29a99e2bec7a3162b6af1d230a676d9/445fc/Download-WebUI-Firmware-en.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e29a99e2bec7a3162b6af1d230a676d9/a0b58/Download-WebUI-Firmware-en.webp 230w", "/en/static/e29a99e2bec7a3162b6af1d230a676d9/bc10c/Download-WebUI-Firmware-en.webp 460w", "/en/static/e29a99e2bec7a3162b6af1d230a676d9/966d8/Download-WebUI-Firmware-en.webp 920w", "/en/static/e29a99e2bec7a3162b6af1d230a676d9/a5d4d/Download-WebUI-Firmware-en.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e29a99e2bec7a3162b6af1d230a676d9/81c8e/Download-WebUI-Firmware-en.png 230w", "/en/static/e29a99e2bec7a3162b6af1d230a676d9/08a84/Download-WebUI-Firmware-en.png 460w", "/en/static/e29a99e2bec7a3162b6af1d230a676d9/c0255/Download-WebUI-Firmware-en.png 920w", "/en/static/e29a99e2bec7a3162b6af1d230a676d9/00d43/Download-WebUI-Firmware-en.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e29a99e2bec7a3162b6af1d230a676d9/c0255/Download-WebUI-Firmware-en.png",
              "alt": "WebUI and Firmware Upgrade VGA Series",
              "title": "WebUI and Firmware Upgrade VGA Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Download the newest Firmware and WebUI files from `}<a href="https://www.instar.de/downloads/" target="_blank" rel="noopener noreferrer">{`download.instar.de`}</a>{`.`}</p>
    <p>{`Sometimes your camera´s WebUI might not behave the way you want. You updated your camera´s software, but it still shows you the old version ? An update should bring new functions to the user interface - but they are nowhere to be seen? Or you simply cannot change a setting? Your Browser might have cached old parts of the user interface and refuses to reload the new or updated files. `}<a parentName="p" {...{
        "href": "/en/Quick_Installation/How_To_Clear_Your_Browsing_History/"
      }}>{`Here`}</a>{` is how you can get rid of your browsers browsing history in several common browsers.`}</p>
    <h3 {...{
      "id": "update-via-the-webui",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#update-via-the-webui",
        "aria-label": "update via the webui permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Update via the WebUI`}</h3>
    <p>{`To update the camera through the Web User Interface simply open System/Update and click on Choose File to upload the bin update file you downloaded earlier. After selecting the file click on submit to start the update. Please wait for the camera to reboot and reload the user interface. `}<strong parentName="p">{`Never unplug the camera during this process`}</strong>{`!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "799px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d87471fdf026d44f3ace047bd9b46eb8/76cea/Update-WebUI-VGA-01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB+UlEQVQoz23RW27iMBQG4Cxw1AWMuozpOpCmi0CCvqCmQ6GFDJHaJBA7N3CuTnCC7dyRWMEoiYbOVP2ejmSd+P8d4VEUv9/efru5+XF3J8vycvkiyxtJknRd3+122+0WQmhAOMzq/wR5s5lOJuPxWJKk4/HIOaf/YJS6CLmue6I0y7JTj1J6Op0IIcJqtZrNZqqq2bZtQMMwTNMwPd9PkgRjHIWRaVh7e08SQkjaNE2e8ygMq7LinAtJHENoDF/dqtr72/tuu0MIeR137+wdy0EHF+2R5/qMsSRJbMtK0zTLMmH9ur7/eT8ajRRFYYzlRc45L67KAh0OCLlt29Z1XVVVXddN09R13d0cxzHG+HK5MMbWr6tf4tPLcgkhdBzHMi3DhM7BOSYJY2xoe9V1ns/n0+l0s5EBALquq4oKdOANXA95BxQiQrqQn6RpKjzPnyeTiaIouq4TQsqq6sL2iqJomgZHGGN8Pp+HwIO2bfM8F0RRfJg+aJoGAFivVuLj02KxgADatm2apmFA0zLCMOScfxFb+i2t19KQGQCgqRoA0O0hhA7u3o/9LO1CftItAwAghHqPMTa85FX1pboaToRdr98FlNKyKj9+U1/72v/vXOR5yXm/rH8AjNKUkMDzoiDAURSGged6OIqioOP7fuAH/ZzFx6aqyj+th5BsSxsfOwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d87471fdf026d44f3ace047bd9b46eb8/e4706/Update-WebUI-VGA-01.avif 230w", "/en/static/d87471fdf026d44f3ace047bd9b46eb8/d1af7/Update-WebUI-VGA-01.avif 460w", "/en/static/d87471fdf026d44f3ace047bd9b46eb8/eb911/Update-WebUI-VGA-01.avif 799w"],
              "sizes": "(max-width: 799px) 100vw, 799px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d87471fdf026d44f3ace047bd9b46eb8/a0b58/Update-WebUI-VGA-01.webp 230w", "/en/static/d87471fdf026d44f3ace047bd9b46eb8/bc10c/Update-WebUI-VGA-01.webp 460w", "/en/static/d87471fdf026d44f3ace047bd9b46eb8/a2266/Update-WebUI-VGA-01.webp 799w"],
              "sizes": "(max-width: 799px) 100vw, 799px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d87471fdf026d44f3ace047bd9b46eb8/81c8e/Update-WebUI-VGA-01.png 230w", "/en/static/d87471fdf026d44f3ace047bd9b46eb8/08a84/Update-WebUI-VGA-01.png 460w", "/en/static/d87471fdf026d44f3ace047bd9b46eb8/76cea/Update-WebUI-VGA-01.png 799w"],
              "sizes": "(max-width: 799px) 100vw, 799px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d87471fdf026d44f3ace047bd9b46eb8/76cea/Update-WebUI-VGA-01.png",
              "alt": "WebUI and Firmware Upgrade VGA Series",
              "title": "WebUI and Firmware Upgrade VGA Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Choose the bin file you downloaded earlier and click on submit.`}</p>
    <h3 {...{
      "id": "update-via-the-camera-tool",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#update-via-the-camera-tool",
        "aria-label": "update via the camera tool permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Update via the Camera Tool`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "641px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e9d94857d883b68672b252f04ccc624c/c7dcc/Update-CamTool-01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACJUlEQVQoz02STW+bQBCG+du55DckVQ+1a5Tee297rCpZvdhJI7vFxizsLuAFAmY/YJePZbfCqaI+p5nRvJp3pNdxXXexWCyXy9Vq5bru6uHB/fRp6bp3d3f39/fvriw+LD6+sVy+Xyw+395+ublx9vsdIQRjVBRFkefl4ZA//bocjozWjFFKKbvCX2H/kEoGIHBAGFIuRCuz4gVDXK9/gq/fXr7/GLu+11M/jK3sulE3UolGdv0w6kn18xDHieP7JwgR58IYO06TGYYqTQ77fVXTyRipVJ4XkzEky7zjEQDARdP1fZ4XEcQOCKM8y8qytNYaY6y1nDOIEaXUWqvHkXNurWWMYowghNfWSCnjJHUACLOMVGX1vxih1yWr9ciuRV3XCCIIEWPMWtO2zWwbgPCcJkKINzGjNYwArevXy+xq4VJVIDhFUSgEt8a0TRPPPwdhmr+kRdlNpjdmtLZiLICoqNlorRx1ybi2tqTsBFGI05IJpc1FtDBOHO8UBqT04izl3Vn0pBnCot6FOMgvWasTJkFBSTOiSvhp4WGCLqJQU8pVgGLndArKssQYI4QQRPFMkp7PSZJgjK9tDCG8XGopZdM0QghGWdO0YRg6EEKttVSq74e+H2pGGed0zsccBkqZUopxTgihlEqlpJRt245aE0KczWYTBMEfzzse/SiKttvN8/Pz09Pj9nFmu90CADzPW6/Xvu/DKDoeD4eDhxHa7X7/BVBigonUnX/UAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e9d94857d883b68672b252f04ccc624c/e4706/Update-CamTool-01.avif 230w", "/en/static/e9d94857d883b68672b252f04ccc624c/d1af7/Update-CamTool-01.avif 460w", "/en/static/e9d94857d883b68672b252f04ccc624c/e1e16/Update-CamTool-01.avif 641w"],
              "sizes": "(max-width: 641px) 100vw, 641px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e9d94857d883b68672b252f04ccc624c/a0b58/Update-CamTool-01.webp 230w", "/en/static/e9d94857d883b68672b252f04ccc624c/bc10c/Update-CamTool-01.webp 460w", "/en/static/e9d94857d883b68672b252f04ccc624c/de042/Update-CamTool-01.webp 641w"],
              "sizes": "(max-width: 641px) 100vw, 641px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e9d94857d883b68672b252f04ccc624c/81c8e/Update-CamTool-01.png 230w", "/en/static/e9d94857d883b68672b252f04ccc624c/08a84/Update-CamTool-01.png 460w", "/en/static/e9d94857d883b68672b252f04ccc624c/c7dcc/Update-CamTool-01.png 641w"],
              "sizes": "(max-width: 641px) 100vw, 641px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e9d94857d883b68672b252f04ccc624c/c7dcc/Update-CamTool-01.png",
              "alt": "WebUI and Firmware Upgrade VGA Series",
              "title": "WebUI and Firmware Upgrade VGA Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Start the INSTAR camera tool and open the Update tab.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "641px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9b7386b64af21fdc902dcc7f962e87d8/c7dcc/Update-CamTool-02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.56521739130435%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACDElEQVQoz23SyW7bMBCAYb1/XqENggQOjKTxC/SaS4GiSZVaayBSK7fhJimmItVmYTvtqT/mQIAHDogvSJKEHKNSSgDgnEspu66N4zg7lec5IYSzv1HadV0URXmeB8botm0ZY31vre2naTLGzPPsvT/8r9/eL8uy3++naQo443n+SgjdbrdhGMZRnGV51xGMcV3XVVUjhDDG5akKY5YkWilr7TiOgQQgHSkKtF6vN5vNarV6eNis1+vPnz5dXV1dX99cXl7erm7v7+/Xd3dfbm6+XlxkUUQoVUoFRpuubaM4atvWGKO1EoIDwOkgAEAppbQSXAzDcPCeSpllGWNMax0YrSklZVkySq21IEEqNU3vO+f+jZumnXPv8+K9B4AwDAkhxphAa11XFcK4qWuttTR9WlR2nOa9d/PBLYf3vd/Nh3HaD27ZzYeGQpKm9PyyBGjbJo6Tvu8/fth7NbiaSvs2W2vath3H8XzlvddaR3FMKTVaB0KIEuOnH0/Pz89FUZQlrqr6V5R++/60TfIw/Pn4+Pjy8oIQKooCY7zdbuM4/ljbGEsIres6z18RQkKcnIDUWp/MCCEE50cjJ0uEMSZP9X0fjONorTXGuGM7QjoAeXR07Chq9/amlCzLchgG51zf98aYnXPLsgQAgnNBGQMAIQRCqGmaM4mzDABomiZNU0rp2S/nXClljP0D8juHlXtvOHAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9b7386b64af21fdc902dcc7f962e87d8/e4706/Update-CamTool-02.avif 230w", "/en/static/9b7386b64af21fdc902dcc7f962e87d8/d1af7/Update-CamTool-02.avif 460w", "/en/static/9b7386b64af21fdc902dcc7f962e87d8/e1e16/Update-CamTool-02.avif 641w"],
              "sizes": "(max-width: 641px) 100vw, 641px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9b7386b64af21fdc902dcc7f962e87d8/a0b58/Update-CamTool-02.webp 230w", "/en/static/9b7386b64af21fdc902dcc7f962e87d8/bc10c/Update-CamTool-02.webp 460w", "/en/static/9b7386b64af21fdc902dcc7f962e87d8/de042/Update-CamTool-02.webp 641w"],
              "sizes": "(max-width: 641px) 100vw, 641px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9b7386b64af21fdc902dcc7f962e87d8/81c8e/Update-CamTool-02.png 230w", "/en/static/9b7386b64af21fdc902dcc7f962e87d8/08a84/Update-CamTool-02.png 460w", "/en/static/9b7386b64af21fdc902dcc7f962e87d8/c7dcc/Update-CamTool-02.png 641w"],
              "sizes": "(max-width: 641px) 100vw, 641px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9b7386b64af21fdc902dcc7f962e87d8/c7dcc/Update-CamTool-02.png",
              "alt": "WebUI and Firmware Upgrade VGA Series",
              "title": "WebUI and Firmware Upgrade VGA Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Press the Search button and choose your camera from the list above. Type in your login user name and password for the camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "635px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dd7d5cfa5c4533f3c48fbb10e752b178/1ddef/Update-CamTool-05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7CAAAOwgEVKEqAAAACNklEQVQozz1SyXLaQBDVt/sXfHHZHHBsFZxSueWSVHEMi4MNtkAsWkYLCCNhBJIAYb/Ua1uZqlc91TP9+vWi3d/fo16v4+7uDo1GA81mA7quo9lsir2+vsbNzQ1qtZrg9vZW/ARjdcbrOr5fXqJ1cQFtNpshiiIEQYD1ei3IsgxJEmO32+FwOAiKosDxeBRUvup+PBxxOJ2QleUn4XQ6xXIZYblcQikFT3mSIC8KIU3THbI8x2azwdvbG9I0xXa7lXtliSLPoVmWhcD3kecFeMryBNdxJMH7+7sQCvZ7GIaB4XAoSX3fx/DpCZ1OVwTM53PEcQzNdR14SmG1WgnB+VxCKQeuUjifz1JqnufIslwqeXl5wWAwAIV0r+r4faXj77cfmPz8hU22h+Y4DhZhKOXwkDAMfSyjCB8fH0JIsK8PDw/oPz7CNE2MDANG/wmDXh/jwTOUZSHZbKjQFYXsUUXo+x6CMBSF1UCoksn5PwhCeJ4HLwgwNk0MnoewbFtEabZtQ/GTH3wRFLCsGehnX6sBbLep9InlcpCLxUJiWDr9ruMiSZLPKTOYTvZxtYrgeUoUMGP8tUp84x9zbAoJh0JwGwj+F0I+cg85OZZEu9vtBaIsTUVlHCd4fX2VSTIR70xCS5CDq6ORnX3aZ3uxZVmKIgYRzEoSWr5zONxXrtHpdPq/VvnXDLRer4fx2JR1GI1GmEwmaLf/yES73S46nc4n2m0Yxkh62Gq10O/3ZY0YU8XZtoN/I+5pHWQybWoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dd7d5cfa5c4533f3c48fbb10e752b178/e4706/Update-CamTool-05.avif 230w", "/en/static/dd7d5cfa5c4533f3c48fbb10e752b178/d1af7/Update-CamTool-05.avif 460w", "/en/static/dd7d5cfa5c4533f3c48fbb10e752b178/d3c94/Update-CamTool-05.avif 635w"],
              "sizes": "(max-width: 635px) 100vw, 635px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dd7d5cfa5c4533f3c48fbb10e752b178/a0b58/Update-CamTool-05.webp 230w", "/en/static/dd7d5cfa5c4533f3c48fbb10e752b178/bc10c/Update-CamTool-05.webp 460w", "/en/static/dd7d5cfa5c4533f3c48fbb10e752b178/a4521/Update-CamTool-05.webp 635w"],
              "sizes": "(max-width: 635px) 100vw, 635px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dd7d5cfa5c4533f3c48fbb10e752b178/81c8e/Update-CamTool-05.png 230w", "/en/static/dd7d5cfa5c4533f3c48fbb10e752b178/08a84/Update-CamTool-05.png 460w", "/en/static/dd7d5cfa5c4533f3c48fbb10e752b178/1ddef/Update-CamTool-05.png 635w"],
              "sizes": "(max-width: 635px) 100vw, 635px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dd7d5cfa5c4533f3c48fbb10e752b178/1ddef/Update-CamTool-05.png",
              "alt": "WebUI and Firmware Upgrade VGA Series",
              "title": "WebUI and Firmware Upgrade VGA Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select to either update the firmware or web user interface of your camera. Please make sure to always update the firmware first before applying a webUI Update.`}</p>
    <p><img parentName="p" {...{
        "src": "./Update-CamTool-03.1.png",
        "alt": "WebUI and Firmware Upgrade VGA Series"
      }}></img></p>
    <p>{`Click on Browse button and select the Firmware or WebUI bin file you downloaded earlier. Press Open to confirm your selection. `}<strong parentName="p">{`Please make sure to unzip the WebUI update file before you upload it to the camera`}</strong>{`!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "639px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bfa93de6500e36de1b98b5117337411f/738b8/Update-CamTool-04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.56521739130435%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7CAAAOwgEVKEqAAAACN0lEQVQoz0VTyW7bUAzUR+eSb0iRBD14yTf01hZo0EsXoCiQXoJYdrxofc+ytcuWXW2xpxgmdgUQ4pNGQ3I4Mu7u7tDr9TAYDMB8OBye836/h6urd7i+vsbt7S1ubm4Ee8IwhoMB3vf7+HB5ic8XFzBGoxEsy8J8PofWWmK1WkErhSAIJGec8vV6jTAMz8EzI01TPD09wXAcB67nIYwiLJdL+EohSRKEYYS6aVHXDdq2Rdt2aJrXnPdTtB0xNY7HI3zfh7GYz6F8haqucTgeUZZbLBYLRHGMw+EglbfbLfI8h2maeH5+lsJKKUwmE4zHY3ieJ894NjzXw1JrbDYb8GrbBp7vQWkl5/1+L8VIOptOYZrjMwlHZlccOY7j/4SrVSAdnAi1Voii6EzIkVjwz8MDHh8fMTbNc7cksW1H8ELoui6WWqHtulfCpoHve7KE4/Gtw6rGtiwxmc1guy50EIju7I7EXCwXxgKG6zhYBQGyLBPCuq7gea5o0r28oCxL7KsKmziG/vYd1v1XLH/9RkAnvOlGCdiAENIutmXBsW0UxQZJEouFbNsS3aIwRJykiLSG8+Ues4+foH78lIK0GImYU8e3kanhWkS2bRtKa+x2e5TlTnQjqRDHCdZpiijPEWXZ2X8nL9IN0+kUBqvwA458IqAPec6yXICMPMtQbrfYFAXSJBEc5eAyif1bVeA+DFqGL/jRbrcToLVYyDgEuK4Dx3HBH4DvOCI3TZtwYUVRCGnXdTLdP5e/Z/TBdp8oAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bfa93de6500e36de1b98b5117337411f/e4706/Update-CamTool-04.avif 230w", "/en/static/bfa93de6500e36de1b98b5117337411f/d1af7/Update-CamTool-04.avif 460w", "/en/static/bfa93de6500e36de1b98b5117337411f/a8125/Update-CamTool-04.avif 639w"],
              "sizes": "(max-width: 639px) 100vw, 639px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bfa93de6500e36de1b98b5117337411f/a0b58/Update-CamTool-04.webp 230w", "/en/static/bfa93de6500e36de1b98b5117337411f/bc10c/Update-CamTool-04.webp 460w", "/en/static/bfa93de6500e36de1b98b5117337411f/d95e5/Update-CamTool-04.webp 639w"],
              "sizes": "(max-width: 639px) 100vw, 639px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bfa93de6500e36de1b98b5117337411f/81c8e/Update-CamTool-04.png 230w", "/en/static/bfa93de6500e36de1b98b5117337411f/08a84/Update-CamTool-04.png 460w", "/en/static/bfa93de6500e36de1b98b5117337411f/738b8/Update-CamTool-04.png 639w"],
              "sizes": "(max-width: 639px) 100vw, 639px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bfa93de6500e36de1b98b5117337411f/738b8/Update-CamTool-04.png",
              "alt": "WebUI and Firmware Upgrade VGA Series",
              "title": "WebUI and Firmware Upgrade VGA Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on Submit to upload the Firmware or WebUI bin file to the camera. Please wait for the camera to install the new software and to reboot. This process might take up to 5min - `}<strong parentName="p">{`please makes sure never to unplug the camera before the update process completed`}</strong>{`!`}</p>
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBox mdxType="DangerBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      